<template>
  <div id="Report">
    <div class="card">
      <div class="card-content" style="text-align: left;">
        <strong class="title is-4">
          Report (full year)
          <!-- <pre>{{isLoadData}}</pre> -->
          <!-- <pre>{{renderChart}}</pre> -->
        </strong>
        <hr>
        <div class="columns is-multiline">
          <div class="column is-one-third-desktop" v-for="(val, key) in branchs" :key="key">
            <article class="message is-info" @click="branch = val">
              <div class="message-header py-1">
                <p class="w-100 cursor_pointer">
                  <i class="fas fa-building"></i>
                  {{ val.name }}
                </p>
              </div>
              <div class="message-body has-text-left p-3 cursor_pointer">
                <small class="is-size-6 is-size-7-mobile">{{ val.detail }}</small>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>

    <div class="card mt-5" v-if="branch.id">
      <div class="card-content pb-0" style="text-align: left;">
        <strong class="title is-4">
          <i class="fas fa-building"></i>
          {{ branch.name }}
          <br class="is-hidden-tablet">
          <small class="is-size-6">( {{ branch.detail }} )</small>
        </strong>
        <hr>
        <div class="columns is-multiline" v-if="node_in_branch.length">
          <div class="column is-one-third-desktop" v-for="(val, key) in node_in_branch" :key="key">
            <article class="message is-link" @click="node = val">
              <div class="message-header py-1">
                <p class="w-100 cursor_pointer">
                  <i class="fas fa-code-branch"></i>
                  {{ val.name }} ({{ val.mac }})
                </p>
              </div>
              <div class="message-body has-text-left p-3 cursor_pointer">
                <small class="is-size-6 is-size-7-mobile">{{ val.detail }}</small>
              </div>
            </article>
          </div>
        </div>
        <div class="columns is-multiline" v-else>
          <div class="message-footer my-4 w-100">
            <center>... Not found device ...</center>
            <br>
            <!-- <button class="button p-6 w-100 is-loading"></button> -->
          </div>
        </div>
      </div>
    </div>

    <div class="card mt-5" v-if="node.id">
      <div class="card-content pb-0" style="text-align: left;">
        <strong class="title is-4">
          <i class="fas fa-code-branch"></i>
          {{ node.name }} ({{ node.mac }})
          <br class="is-hidden-tablet">
          <small class="is-size-7">({{ node.detail }})</small>
        </strong>
        <hr>
      </div>
      <!-- <pre>{{inputDate}}</pre> -->
      <div class="columns is-desktop">
        <div class="column is-full pb-0">
          <!-- <div class="pb-1">
            <button class="button is-small vpdDateButton" :class="{'is-loading':isLoadData}"
              @click="previusYear"> <i class="fas fa-caret-left"></i> </button>
            <datepicker
              v-model="inputDate" :format="'yyyy'" minimum-view="year"
              style="width:145px !important; position: relative !important; display: inline-block !important;"
              input-class="input is-small has-text-centered" :disabled="isLoadData">
            </datepicker>
            <button class="button is-small vpdDateButton" :class="{'is-loading':isLoadData}"
              @click="nextYear"> <i class="fas fa-caret-right"></i> </button>
          </div> -->
          <div class="pb-1">
            <datepicker v-model="inputDate" :format="'yyyy'" minimum-view="year"
              style="width:205px !important; position: relative !important; display: inline-block !important;"
              input-class="input is-small has-text-centered" :disabled="isLoadData">
            </datepicker>
          </div>
          <div class="pb-1">
            <div id="select-half-year" class="select is-small" :class="{ 'is-loading': isLoadData }">
              <select class="has-text-centered" v-model="inputHalfYear" style="width:205px;">
                <option value="1" :disabled="isLoadData">{{ half_year_label[1] }}</option>
                <option value="2" :disabled="isLoadData">{{ half_year_label[2] }}</option>
                <option value="3" :disabled="isLoadData">{{ half_year_label[3] }}</option>
              </select>
            </div>
          </div>
          <div class="pb-1">
            <button type="button" class="button is-small is-primary" :class="{ 'is-loading': isLoadData }"
              style="width:205px;" @click="refreshButton">
              <span class="mr-1">Refresh</span>
              <i class="fas fa-retweet"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="columns is-desktop">
        <div class="column is-full pt-0" v-if="renderChart">
          <div style="width:100%; height:375px;" v-if="!jsonData.length">
            <br><br><br><br>
            <p> <b>Not Found Data</b> </p>
            <small>{{ half_year_label[inputHalfYear] }} - {{ formatYear(inputDate) }}</small>
          </div>
          <small v-else>
            <apexcharts height="375" type="bar" :options="chartOptions" :series="series"></apexcharts>
            <!-- <pre>{{ series }}</pre> -->
            <!-- {{ jsonData }} -->
            <!-- {{ jsonData0 }} -->
            <!-- {{ jsonData1 }} -->
            <div class="dropdown is-up is-hoverable">
              <div class="dropdown-menu" id="setting-start-date" role="menu">
                <div class="dropdown-content">
                  <div class="dropdown-item p-2">
                    <!-- <pre>{{jsonCutoff}}</pre> -->
                    <div class="mb-3" v-for="(val, key) in jsonCutoff" :key="key">
                      <article class="message"
                        :class="{ 'is-success': parseInt(val.enable), 'is-danger': !parseInt(val.enable) }">
                        <div class="message-header py-2 px-0">
                          <small class="w-100 is-size-7">
                            <i class="fas fa-solar-panel"></i>
                            Device{{ val.number }} <small v-if="val.detail">({{ val.detail }})</small>
                          </small>
                        </div>
                        <div class="message-body p-1">
                          <div class="field has-addons" v-if="!savingCutoffDate">
                            <p class="control">
                              <a class="button is-static is-rounded is-small">
                                Cut-Off Date
                              </a>
                            </p>
                            <p class="control">
                              <input class="input is-rounded is-small" type="number" min="1" max="31"
                                v-model="jsonCutoff[key].cutoff" placeholder="cut-off date">
                            </p>
                          </div>
                          <button class="button has-background-white is-small is-loading w-100" v-else></button>
                        </div>
                      </article>
                    </div>
                    <a class="button is-small is-info w-100" @click="submitCutOffDate"> Save </a>
                  </div>
                </div>
              </div>
              <div class="dropdown-trigger">
                <button class="button" aria-haspopup="true" aria-controls="setting-start-date">
                  <span>Setting cut-off date </span>
                  <span class="icon is-small">
                    <i class="fas fa-angle-up" aria-hidden="true"></i>
                  </span>
                </button>
              </div>
            </div>
          </small>
        </div>
        <div class="column is-full py-0" v-else>
          <button class="button is-white is-loading" style="width:100%; height:375px;"></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import DatePick from 'vue-date-pick';
// import 'vue-date-pick/dist/vueDatePick.css';

import Datepicker from 'vuejs-datepicker';
import VueApexCharts from 'vue-apexcharts'

let HALF_YEAR_LABEL = ["", "full year!", "first half of the year", "half a year"];
let MONTH_LABEL = [
  [],
  ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
  ["Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
];

export default {
  name: 'MemberReport',
  props: {
    userData: Object,
    loaded: Object,
    branchs: Array,
    nodes: Array,
    devices: Array,
  },
  // components: { DatePick },
  components: { Datepicker, apexcharts: VueApexCharts, },
  data() {
    return {
      branch: {},
      node: {},
      isLoadData: false,
      renderChart: false,
      inputDate: this.formatDate(new Date()),
      inputHalfYear: 1,
      timeData: null,
      jsonCutoff: [],
      jsonData: [],
      jsonData0: [],
      jsonData1: [],
      half_year_label: HALF_YEAR_LABEL,
      savingCutoffDate: false,
      // chartOptions: {
      //   xaxis: {
      //     categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
      //   }
      // },
      // series: [
      //   {
      //     name: 'series-1',
      //     data: [30, 40, 45, 50, 49, 60, 70, 91, 30, 40, 45, 50, 49, 60, 70, 91]
      //   },
      //   {
      //     name: 'series-2',
      //     data: [31, 41, 41, 51, 41, 61, 71, 90, 31, 41, 41, 51, 41, 61, 71, 90]
      //   }
      // ]
    }
  },
  mounted() {
    if (!this.loaded.get_branch) {
      this.$emit('get_branch');
    }
  },
  methods: {
    get_node(n_id) {
      this.$emit('get_node', n_id)
    },
    get_device(d_id) {
      this.$emit('get_device', d_id)
    },
    async get_report() {
      if (!this.device_in_node.length) {
        this.get_device(this.node.id);
        if (!this.isLoadData) {
          setTimeout(() => this.get_report(), 1000);
        }
      } else {
        this.isLoadData = true;
        var url = this.api + 'user/get_report_full_year/' + this.secret;
        var data = new FormData();
        let d_id = [];
        this.device_in_node.forEach((e) => {
          d_id.push(e.id);
        });
        data.set("d_id", JSON.stringify(d_id));
        // data.set("half", this.inputHalfYear);
        data.set("year", this.formatYear(this.inputDate));
        data.set("secret", this.secret);
        this.Axios.post(url, data)
          .then((response) => {
            console.log(response.data);
            if (response.data.error == 0) {
              this.jsonCutoff = response.data.cutoff;
              this.jsonData = response.data.data;
              this.jsonData0 = response.data.data0;
              this.jsonData1 = response.data.data1;
            }
            setTimeout(() => this.renderChart = true, 100);
          })
          .catch((error) => {
            console.error(error);
          })
          .then(() => this.isLoadData = false);
      }
    },
    formatDate(date = null) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    },
    previusYear() {
      var d = new Date(this.inputDate),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      this.inputDate = [year - 1, month, day].join('-');
    },
    nextYear() {
      var d = new Date(this.inputDate),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      this.inputDate = [year + 1, month, day].join('-');
    },
    formatYear() {
      var d = new Date(this.inputDate);

      return d.getFullYear();
    },
    Total_Energy(log) {
      const IMP_ENERGY = (log.Imp_Energy !== "-") ? parseFloat(log.Imp_Energy) : parseInt(0);
      const EXP_ENERGY = (log.Exp_Energy !== "-") ? parseFloat(log.Exp_Energy) : parseInt(0);
      return IMP_ENERGY + EXP_ENERGY;
    },
    doSearchData() {
      clearTimeout(this.timeData)
      this.jsonData = [];
      this.jsonData0 = [];
      this.isLoadData = false;
      this.renderChart = false;
      this.timeData = setTimeout(() => {
        this.get_report();
      }, 1000)
    },
    device_name(device) {
      return (device.detail) ? "Device-" + device.number + " (" + device.detail + ")" : "Device-" + device.number;
    },
    refreshButton() {
      this.get_report();
    },
    submitCutOffDate() {
      if (this.savingCutoffDate) return false;

      console.log(this.jsonCutoff);

      this.savingCutoffDate = true;
      var url = this.api + 'device/update_cutoff_date/' + this.secret;
      var data = new FormData();
      let device = [];
      this.jsonCutoff.forEach((e) => {
        device.push({ id: e.id, cutoff: e.cutoff });
      });
      data.set("device", JSON.stringify(device));
      data.set("secret", this.secret);
      this.Axios.post(url, data)
        .then((response) => {
          console.log(response.data);
          if (response.data.error == 0) {
            setTimeout(() => {
              this.savingCutoffDate = false;
              this.doSearchData();
            }, 1000);
          }
        })
        .catch((error) => {
          console.error(error);
        })
    },
    RenderJsonData() {
      if (!this.jsonData.length) return [];

      let array = [];
      this.jsonData.forEach((e) => {
        // console.log(e);
        let findIndex = array.findIndex(x => x.name == this.device_name(e));
        if (findIndex < 0) array.push({ name: this.device_name(e), data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] })
      });
      // this.jsonData.forEach((e) => {
      //   let findIndex = array.findIndex(x => x.name == this.device_name(e));
      //   if(findIndex>=0){
      //     array[findIndex].data[ (e.mon-((this.inputHalfYear-1)*6))-1 ] = this.Total_Energy(e);
      //     // console.log(e.mon, e.number, this.Total_Energy(e) );
      //     let number = parseFloat( array[findIndex].data[ (e.mon-((this.inputHalfYear-1)*6))-2 ] ).toFixed(2);
      //     // console.log("number", number );
      //     if(number>0){
      //       // console.log( this.Total_Energy(e) - number );
      //       array[findIndex].data[ (e.mon-((this.inputHalfYear-1)*6))-2 ] = ( this.Total_Energy(e) - number ).toFixed(2);
      //     }
      //   }
      // });
      this.jsonData.forEach((e) => {
        let findIndex = array.findIndex(x => x.name == this.device_name(e));
        if (findIndex >= 0) {
          array[findIndex].data[e.mon - 1] = this.Total_Energy(e);
          console.log(`${this.device_name(e)}: month ${e.mon}, number ${e.number}, %cTotal_Energy ${this.Total_Energy(e)} `, 'background: yellow; color: black');
          let number = parseFloat(array[findIndex].data[e.mon - 2]).toFixed(2);
          console.log(` number ${number} %c${(number > 0)} `, 'background: red; color: white');
          if (number > 0) {
            console.log(` %c${this.Total_Energy(e)} - ${number} = ${this.Total_Energy(e) - number} `, 'background: green; color: white');
            array[findIndex].data[e.mon - 2] = (this.Total_Energy(e) - number).toFixed(2);
          } else {
            // // ------------------------------- NUM GAP -----------------------------------
            // let num_gap = array[findIndex].data[ e.mon-3 ];
            // array[findIndex].data[ e.mon-3 ] = ( this.Total_Energy(e) - num_gap ).toFixed(2);
          }
        }
      });

      if (this.jsonData1.length) {
        this.jsonData1.forEach((e) => {
          // if( (e.mon>(this.inputHalfYear-1)*6 && e.mon<=this.inputHalfYear*6) || (e.mon>this.inputHalfYear*6) ){
          //   // console.log("mon",e.mon);
          //   let findIndex = array.findIndex(x => x.name == this.device_name(e));
          //   if(findIndex>=0){
          //     let number = parseFloat( array[findIndex].data[ (e.mon-((this.inputHalfYear-1)*6))-1 ] ).toFixed(2);
          //     // console.log("number",number);
          //     if(number>0){
          //       // console.log("number", this.Total_Energy(e), number, this.Total_Energy(e) - number);
          //       array[findIndex].data[ (e.mon-((this.inputHalfYear-1)*6))-1 ] = ( this.Total_Energy(e) - number ).toFixed(2);
          //     }else{
          //       number = parseFloat( array[findIndex].data[ (e.mon-((this.inputHalfYear-1)*6))-2 ] ).toFixed(2);
          //       array[findIndex].data[ (e.mon-((this.inputHalfYear-1)*6))-2 ] = ( this.Total_Energy(e) - number ).toFixed(2);
          //     }
          //   }
          // }
          let findIndex = array.findIndex(x => x.name == this.device_name(e));
          if (findIndex >= 0) {
            // console.log(e.mon);
            // console.log(this.Total_Energy(e));
            // console.log(array[findIndex].name);
            // console.log(array[findIndex].data);
            // console.log("------------",e.mon-1, array[findIndex].data[ e.mon-1 ]);
            for (var i = 1; i <= 10; i++) {
              let number = parseFloat(array[findIndex].data[e.mon - i]).toFixed(2);
              console.log(this.device_name(e), "month-", i, "number", number);
              if (number > 0) {
                // code เดิม
                console.log(array[findIndex].data[e.mon - i], this.Total_Energy(e), number);
                array[findIndex].data[e.mon - i] = (this.Total_Energy(e) - number).toFixed(2);
                console.log(array[findIndex].data[e.mon - i]);
                i = 10;

                // // ใช้ที่ใหม่กว่าลบกับค่าก่อนหน้า
                // // เคสไม่ปกติข้อมูลใหม่น้อยกว่า (เกิดการเปลี่ยนเครื่องแต่ใช้เลขเดิม)
                // // จะใช้เลขเดิมเป็นตัวตั้ง (เลขใหม่จะน้อยกว่า) บวกเลขใหม่เข้าไป
                // let calculate_num = (this.Total_Energy(e)>number) ? this.Total_Energy(e)-number : this.Total_Energy(e)+number
                // array[findIndex].data[ e.mon-i ] = ( calculate_num ).toFixed(2);
                // i = 10;
              }
            }
          }
        });
      }

      // if(this.jsonData0.length && this.inputHalfYear==3){
      //   this.jsonData0.forEach((e) => {
      //     let findIndex = array.findIndex(x => x.name == this.device_name(e));
      //     if(findIndex>=0){
      //       let number = parseFloat( array[findIndex].data[5] ).toFixed(2);
      //       // console.log("number",number);
      //       if(number>0){
      //         // console.log("number", this.Total_Energy(e), number, this.Total_Energy(e) - number);
      //         array[findIndex].data[5] = ( this.Total_Energy(e) - number ).toFixed(2);
      //       }
      //     }
      //   });
      // }

      return array;
    },
  },
  computed: {
    node_in_branch() {
      if (!this.branch.id) return [];

      return this.nodes.filter(node => { return parseInt(node.b_id) == parseInt(this.branch.id) });
    },
    count_device_in_branch() {
      if (!this.node_in_branch.length) return 0;

      let count = 0;
      this.node_in_branch.forEach((node) => {
        this.devices.forEach((device) => {
          if (device.n_id == node.id) count++;
        });
      });
      return count;
    },
    device_in_node() {
      if (!this.node.id) return [];

      return this.devices.filter(device => { return parseInt(device.n_id) == parseInt(this.node.id) });
    },
    chartOptions() {
      return {
        xaxis: {
          categories: MONTH_LABEL[this.inputHalfYear]
        },
        dataLabels: {
          enabled: false
        }
      }
    },
    seriesArray1() {
      let array = this.RenderJsonData();
      return array
    },
    seriesArray2() {
      let array = this.RenderJsonData();
      array.forEach((e, i) => {
        array[i].data = e.data.slice(0, 6);
      });
      return array
    },
    seriesArray3() {
      let array = this.RenderJsonData();
      array.forEach((e, i) => {
        array[i].data = e.data.slice(6, 12);
      });
      return array
    },
    series() {
      if (!this.jsonData.length) return [];

      switch (parseInt(this.inputHalfYear)) {
        case 1:
          return this.seriesArray1;
        case 2:
          return this.seriesArray2;
        case 3:
          return this.seriesArray3;
        default:
          return [];
      }
    }
  },
  watch: {
    branch(val) {
      if (val.id) {
        this.get_node(val.id);
        this.node = {};
      }
    },
    node(val) {
      if (val.id) {
        // this.get_device(val.id);
        this.isLoadData = false;
        this.renderChart = false;
        this.get_report();
      }
    },
    inputDate() {
      this.inputHalfYear = 1;
      setTimeout(() => this.doSearchData(), 1);
    },
    // inputHalfYear(){
    //   this.doSearchData();
    // },
  }
}
</script>

<style media="screen">
/* .select select:not([multiple]) */
#select-half-year select:not([multiple]) {
  padding-right: 0 !important;
}
</style>
